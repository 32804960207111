<template>
  <el-dialog :lock-scroll="true" :top="'50px'" :width="'60%'" v-model="show" title="Teste">

    <template v-slot:title>
      <div  style="border-bottom: #e0e0e0 1px solid;padding: 20px;">
        <div class="modal-title" style="display: flex;width: calc(100% - 50px)">
          {{KT('invoice.add')}}
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div  style="border-top: #e0e0e0 1px solid;padding: 20px;display: flex;justify-content: space-between;">


        <el-button
            type="danger" @click="doCancel()"  >
          {{ KT('cancel') }}
        </el-button>


        <el-button
            type="primary"  @click="doAdd()"  >
          {{KT('ok')}}
        </el-button>






      </div>
    </template>

    <div style="padding: 20px">
    <el-form :label-position="'top'">

      <el-form-item :label="$t('user.billingDescription')">
        <el-input
            v-model="formData.attributes['tarkan.billingDescription']"
            :placeholder="store.getters['server/getAttribute']('tarkan.billingDescription','')"
            :size="'large'"></el-input>
      </el-form-item>

      <el-form-item :label="$t('invoice.dueDate')">
        <el-date-picker v-model="formData.attributes['tarkan.billingDate']" :size="'large'"></el-date-picker>
      </el-form-item>

      <el-form-item :label="$t('user.billingPrice')">
        <el-input v-model="formData.attributes['tarkan.billingPrice']"

                  type="text"

                  :formatter="(value) => (value=='' || isNaN(parseFloat(value)))?'':(parseFloat(value)/100).toFixed(2).replace('.',',')"
                  :parser="(value) => value==''?value:value.replace(/\$\s?|(,*)/g, '')"


                  :placeholder="parseFloat(store.getters['server/getAttribute']('tarkan.billingPrice','')/100).toFixed(2)"
                  :size="'large'"></el-input>
      </el-form-item>

      <el-form-item :label="$t('user.billingInterest')">
        <el-input v-model="formData.attributes['tarkan.billingInterest']"
 type="text" 
      :formatter="(value) => (value=='' || isNaN(parseFloat(value)))?'':(parseFloat(value)/100).toFixed(2).replace('.',',')"
                  :parser="(value) => value==''?value:value.replace(/\$\s?|(,*)/g, '')"



                  :placeholder="store.getters['server/getAttribute']('tarkan.billingInterest','')"
                  :size="'large'"></el-input>
      </el-form-item>

    <el-form-item :label="$t('user.billingFine')">
  <div style="display: flex; align-items: center; gap: 10px;">
    
    <!-- SWITCH para elegir entre Porcentaje y Fijo -->
    <el-switch
      v-model="formData.attributes['tarkan.billingFineMode']"
      :inactive-text="$t('fixed')"
      :active-text="$t('percent')"
      :active-value="'PERCENTAGE'"
      :inactive-value="'FIXED'"
    ></el-switch>

    <!-- INPUT con formato correcto para solo números y punto decimal -->
    <el-input 
      v-model="formData.attributes['tarkan.billingFine']"
      type="text" 
      :formatter="(value) => (value=='' || isNaN(parseFloat(value)))?'':(parseFloat(value)/100).toFixed(2).replace('.',',')"
                  :parser="(value) => value==''?value:value.replace(/\$\s?|(,*)/g, '')"


                  :placeholder="parseFloat(store.getters['server/getAttribute']('tarkan.billingFine','')/100).toFixed(2)"
                  :size="'large'"></el-input>
  
  </div>
</el-form-item>





      <el-form-item :label="$t('user.billingDisccountMode')" >
        <el-switch
            v-model="formData.attributes['tarkan.billingDisccountMode']"
            :inactive-text="$t('fixed')"
            :active-text="$t('percent')"
            :active-value="'PERCENTAGE'"
            :inactive-value="'FIXED'"
        >
        </el-switch>
      </el-form-item>


      <el-form-item :label="$t('user.billingDisccount')" :size="'large'">
        <el-input v-if="formData.attributes['tarkan.billingDisccountMode'] == 'PERCENTAGE'" v-model="formData.attributes['tarkan.billingDisccount']"

                  :placeholder="store.getters['server/getAttribute']('tarkan.billingDisccount','')"
                  :size="'large'"></el-input>

        <el-input v-else v-model="formData.attributes['tarkan.billingDisccount']"

                   type="text" 
      :formatter="(value) => (value=='' || isNaN(parseFloat(value)))?'':(parseFloat(value)/100).toFixed(2).replace('.',',')"
                  :parser="(value) => value==''?value:value.replace(/\$\s?|(,*)/g, '')"

                  :placeholder="store.getters['server/getAttribute']('tarkan.billingDisccount','')"
                  :size="'large'"></el-input>
      </el-form-item>


      <el-form-item :label="$t('user.billingDisccountDays')" :size="'large'">
        <el-input v-model="formData.attributes['tarkan.billingDisccountDays']"

                  :placeholder="store.getters['server/getAttribute']('tarkan.billingDisccountDays','')"
                  :size="'large'"></el-input>
      </el-form-item>
    </el-form></div>

  </el-dialog>
</template>


<script setup>




import 'element-plus/es/components/input/style/css'
import 'element-plus/es/components/button/style/css'
import 'element-plus/es/components/switch/style/css'
import 'element-plus/es/components/select/style/css'
import 'element-plus/es/components/option/style/css'
import 'element-plus/es/components/dialog/style/css'
import 'element-plus/es/components/tab-pane/style/css'
import 'element-plus/es/components/tabs/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/checkbox/style/css'

import {ElButton, ElDatePicker, ElDialog, ElFormItem, ElInput, ElMessage, ElSwitch} from "element-plus";

import {ref,defineExpose} from 'vue';


import {useStore} from 'vuex'

const store = useStore();
const show = ref(false);

import KT from "@/tarkan/func/kt";






const user = ref(false);
const formData = ref({attributes: {}});


const doCancel = ()=>{
  show.value = false;
}

const doAdd = ()=>{
  fetch("/tarkan/invoices/manager/"+user.value.id+"/save",{
    method: "POST",
    headers:{
      "content-type": "application/json"
    },
    body: JSON.stringify(formData.value.attributes)
  }).then((r)=>{

    r.json().then((j)=> {

      if(j.error){
        ElMessage.success(KT('invoice.'+j.error));
      }else {

        user.value.invoices.push(j);


        ElMessage.success(KT('invoice.addSuccess'));

        show.value = false;
      }
    });

  });
}
const showInvoices = (_user)=>{

    show.value = true;
    user.value = _user;

}


defineExpose({
  showInvoices
});





</script>

<style>

.itm{
  border-bottom: silver 1px dotted;
}

.itm div{
  border-right: silver 1px dotted;
}


.tr1{
  background: #f3f3f3;
}

.tr2{
  background: #f8f8f8;
}

.selected{
  background: rgba(5, 167, 227, 0.05) !important;
}

.itm div:last-child{
  border-right: none;
}

.el-select.el-select--large{
  width: 100%;
}

.el-dialog__header,.el-dialog__body,.el-dialog__footer{
  padding: 0px !important;
}

.el-dialog__footer{
  margin-top: 0px;
}

.el-tabs__nav-wrap{
  padding-left: 20px;
  padding-right: 20px;
}

.el-tabs__content{
  padding-left: 20px;
  padding-right: 20px;
}




.danger{
  --el-button-text-color: var(--el-color-danger) !important;
  --el-button-bg-color: #fef0f0 !important;
  --el-button-border-color: #fbc4c4 !important;
  --el-button-hover-text-color: var(--el-color-white) !important;
  --el-button-hover-bg-color: var(--el-color-danger) !important;
  --el-button-hover-border-color: var(--el-color-danger) !important;
  --el-button-active-text-color: var(--el-color-white) !important;
  --el-button-active-border-color: var(--el-color-danger) !important;
}

</style>